import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
const tz = dayjs.tz.guess()

export const defaultDateFormat = 'DD.MM.YYYY'
export const defaultTimeFormat = 'HH:mm:ss'
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`

export const getEnglishLocaleCodes = () => [
  'en-au',
  'en-bz',
  'en-ca',
  'en-cb',
  'en-gb',
  'en-in',
  'en-ie',
  'en-jm',
  'en-nz',
  'en-ph',
  'en-za',
  'en-tt',
  'en-us'
]

export function formatSqlDate (date) {
  return dayjs(date)
    .tz(tz)
    .format(defaultDateFormat)
}

export function formatSqlTime (date) {
  return dayjs(date)
    .tz(tz)
    .format(defaultTimeFormat)
}

export function formatTime (date, format = 'HH:mm') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatSqlDateTime (dateTime, format = null) {
  const localeDateTimeFormat = !format ? `${defaultDateFormat} ${defaultTimeFormat}` : format

  return dayjs(dateTime)
    .tz(tz)
    .format(localeDateTimeFormat)
}

export function formatIsoDate (date, format = 'YYYY-MM-DD') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatIsoTime (date, format = 'HH:mm:ss') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatIsoString (date) {
  return dayjs(date).toISOString()
}

export function parseDmyDate (date) {
  return dayjs(date, defaultDateFormat)
    .format('YYYY-MM-DD')
}

export function parseDmyDateTime (dateTime) {
  const dateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`

  return dayjs(dateTime, dateTimeFormat)
    .format('YYYY-MM-DD HH:mm:ss')
}
