import groups from '@/modules/fleet-module/groups/routes/index.child'
import vehicle from '@/modules/fleet-module/vehicles/routes/show.child'
import vehicleAlarms from '@/modules/fleet-module/vehicle-alarms/routes/index.child'
import vehicleTags from '@/modules/fleet-module/vehicle-tags/routes/index.child'
import vehicles from '@/modules/fleet-module/vehicles/routes/index.child'
import communication from '@/modules/fleet-module/communication/routes/index.child'

export default {
  path: '/fleet',
  redirect: {
    name: 'NotFound',
    params: ['/fleet']
  },
  component: () => import(/* webpackChunkName: "fleet" */ '../views/FleetView.vue'),
  meta: {
    exclude: false,
    requiresAuth: true
  },
  children: [
    groups,
    vehicle,
    vehicleAlarms,
    vehicleTags,
    vehicles,
    communication
  ]
}
