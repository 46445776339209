import { wktToGeoJSON } from '@terraformer/wkt'
import { createNamespacedHelpers } from 'vuex'
import L from 'leaflet'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('satellite-tracking/tracking-history')

export default {
  data () {
    return {
      userLocationsGroup: null,
      partnerLocationsGroup: null
    }
  },

  computed: {
    ...mapGetters(['userLocations', 'partnerLocations'])
  },

  watch: {
    userLocations: {
      deep: true,
      handler (locations) {
        if (this.showUserLocations && locations.length) {
          locations.forEach(location => {
            location.data.includes('CIRCLE')
              ? this.handleCircleData(location, 'user')
              : this.handleOtherShapes(location)
          })
        }
        else {
          this.userLocationsGroup?.clearLayers()
        }
      }
    },

    partnerLocations: {
      deep: true,
      handler (locations) {
        if (this.showPartnerLocations && locations.length) {
          locations.forEach(location => this.handleCircleData(location, 'partner'))
        }
        else {
          this.partnerLocationsGroup.clearLayers()
        }
      }
    },

    // Fetch user locations when appropriate option is selected, or clear layer group otherwise
    showUserLocations: {
      immediate: true,
      handler (showUserLocations) {
        showUserLocations
          ? this.getUserLocations()
          : this.userLocationsGroup?.clearLayers()
      }
    },

    // Fetch partner locations when appropriate option is selected, or clear layer group otherwise
    showPartnerLocations: {
      immediate: true,
      handler (showPartnerLocations) {
        showPartnerLocations
          ? this.getPartnerLocations()
          : this.partnerLocationsGroup?.clearLayers()
      }
    }
  },

  methods: {
    ...mapActions(['getUserLocations', 'getPartnerLocations']),

    initializeLocationLayerGroups () {
      this.$nextTick(() => {
        // TODO after testing of refactored MapContainer we should remove map or liveTracking map bindings
        // TODO depends on what result will be
        const map = this.$refs.map?.mapObject
        const liveTrackingMap2 = this.$refs.liveTrackingMap?.mapObject

        if (map) {
          this.userLocationsGroup = new window.L.FeatureGroup()
          this.userLocationsGroup.addTo(map)
          this.partnerLocationsGroup = new window.L.FeatureGroup()
          this.partnerLocationsGroup.addTo(map)
        }
        else if (liveTrackingMap2) {
          this.userLocationsGroup = new window.L.FeatureGroup()
          this.userLocationsGroup.addTo(liveTrackingMap2)
          this.partnerLocationsGroup = new window.L.FeatureGroup()
          this.partnerLocationsGroup.addTo(liveTrackingMap2)
        }
      })
    },

    handleCircleData (location, type) {
      let coordinates = null
      let radius = null
      let circle = null
      let marker = null
      let layerGroup = null

      if (type === 'user') {
        layerGroup = this.userLocationsGroup
        const [latLongPart, radiusPart] = location.data.split(',')

        coordinates = latLongPart.replace('CIRCLE', '')
          .replace('(', '')
          .trim()
          .split(' ')
          .map(value => parseFloat(value))

        radius = parseFloat(radiusPart.replace(')', '').trim())
      }
      else {
        layerGroup = this.partnerLocationsGroup
        radius = location.contact_point
        coordinates = [
          +location.geolocation.lat,
          +location.geolocation.lng
        ]
      }

      // Check if location type is point
      if (location.data_type?.id === 4) {
        L.Icon.Default.mergeOptions({
          iconRetinaUrl: '/img/icons/icon_sensor_start.svg',
          iconUrl: '/img/icons/icon_sensor_start.svg',
          shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
          iconSize: [26, 26],
          iconAnchor: [13, 13]
        })

        marker = new window.L.Marker(coordinates)
        this.bindTooltipToGeolocationLayer(marker, this.getUserLocationTooltipContent(location))
      }
      else if (
        type === 'partner' &&
        location.partner?.media?.[0]?.original_url
      ) {
        const iconOriginalUrl = location.partner?.media?.[0]?.original_url
        marker = new window.L.Marker(coordinates)

        marker.setIcon(new L.Icon({
          iconRetinaUrl: '/public/administration' + iconOriginalUrl.substr(iconOriginalUrl.indexOf('/storage')),
          iconUrl: '/public/administration' + iconOriginalUrl.substr(iconOriginalUrl.indexOf('/storage')),
          className: 'partner-pin-logo'
        }))

        this.bindTooltipToGeolocationLayer(marker, this.getUserLocationTooltipContent(location))
      }
      else {
        circle = new window.L.Circle(coordinates, { radius })
        circle.setStyle({ color: location.color || '#333' })

        this.bindTooltipToGeolocationLayer(
          circle,
          type === 'user'
            ? this.getUserLocationTooltipContent(location)
            : this.getPartnerLocationTooltipContent(location)
        )
      }

      if (circle) {
        layerGroup.addLayer(circle)
      }
      if (marker) {
        layerGroup.addLayer(marker)
      }
    },

    handleOtherShapes (location) {
      const geoJsonData = wktToGeoJSON(location.data)

      window.L.geoJson(geoJsonData, {
        coordsToLatLng: function (coords) {
          // latitude , longitude, altitude
          return new window.L.LatLng(coords[0], coords[1], coords[2])
        },
        onEachFeature: (feature, layer) => {
          if (!(layer instanceof window.L.Marker)) {
            layer.setStyle({ color: location.color })
          }

          this.bindTooltipToGeolocationLayer(layer, this.getUserLocationTooltipContent(location))

          this.userLocationsGroup?.addLayer(layer)
        }
      })
    },

    bindTooltipToGeolocationLayer (layer, content) {
      layer.bindTooltip(content, {
        direction: 'top'
      })
    },

    getUserLocationTooltipContent (location) {
      return `
        ${location.name}
        ${location.default ? `<br>${this.$t('locations/location.main_location')}` : ''}
      `
    },

    getPartnerLocationTooltipContent (partnerLocation) {
      return `
        ${partnerLocation.partner_and_type}
        <br/>
        ${partnerLocation.name}
        <br/>
        ${this.$t('base.group') + ': ' + partnerLocation.partner?.group.name}
      `
    }
  }
}
